<template>
    <div class="panel panel-default toggle_container">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Email</label>
                <input type=text class='form-control' v-model="state.optionFields.email.val">
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>First Name</label><input type=text class='form-control' v-model="state.optionFields.firstName.val" >
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Last Name</label><input class='form-control' type=text  v-model="state.optionFields.lastName.val">
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>User Type</label>
                <select class="cdi_select form-control" v-model="state.optionFields.userType.val">
                    <option value="0">All</option>
                    <option v-for="[cdiLookupID, userType] in cache.userCache.USER_TYPES" v-bind:key="cdiLookupID" :value="cdiLookupID">{{userType}}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Company</label>
                <Typeahead cdi_type="company" :displayValue="state.optionFields.companyName.val" v-bind:ID.sync="state.optionFields.companyId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Branch</label>
                <Typeahead cdi_type="branch" v-bind:ID.sync="state.optionFields.branchId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>City</label>
                <Typeahead cdi_type="city" v-bind:ID.sync="state.optionFields.city.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>State</label>
                <select class="cdi_select form-control" v-model="state.optionFields.state.val">
                    <option value="0">All</option>
                    <option v-for="[stateAbbr, state] in cache.stateCache.STATES" v-bind:key="stateAbbr" :value="stateAbbr">{{state}}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>CDI Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationId.val">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Zipcode</label><input type=text class='form-control' v-model="state.optionFields.zip.val" >
            </div>
            <div v-bind:class="{ toggle_hide: toggleHide }" class="col-md-3 col-sm-4 col-xs-6 form-group  ">
                <label>Phone</label><input type=text  class='form-control' v-model="state.optionFields.phone.val"  >
             </div>
            <div v-bind:class="{ toggle_hide: toggleHide }" class="col-md-3 col-sm-4 col-xs-6 form-group  ">
                <label>Regional Sales Rep</label>
                <Typeahead ref="regionalSalesRep" cdi_type="sales_employee" v-bind:ID.sync="state.optionFields.outsideSalesId.val" />
            </div>
            <div v-bind:class="{ toggle_hide: toggleHide }" class="col-md-3 col-sm-4 col-xs-6 form-group  ">
                <label>Sales Support Rep</label>
                <Typeahead ref="salesSupportRep" cdi_type="sales_employee" v-bind:ID.sync="state.optionFields.insideSalesId.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
              <label>National Sales Team</label>
              <select class="form-control" v-model="state.optionFields.nationalSalesTeams.val">
                <option value="0"></option>
                <option v-for="[id, location] in nationalSalesTeams" v-bind:key="id" :value="id">{{ location }}</option>
              </select>
            </div>
            <div v-bind:class="{ toggle_hide: toggleHide }" class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Brand</label>
                <select class="cdi_select form-control" v-model="state.optionFields.brand.val">
                    <option value="0">All</option>
                    <option v-for="[brandId, brand] in cache.brandCache.BRANDS" v-bind:key="brandId" :value="brandId">{{ brand }}</option>
                </select>
            </div>
            <div v-bind:class="{ toggle_hide: toggleHide }" class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>CDI Role</label>
                <select class="cdi_select form-control" v-model="state.optionFields.cdiRole.val">
                    <option value="0">All</option>
                    <option v-for="[roleId, role] in cache.cdiRoleCache.ROLES" v-bind:key="roleId" :value="roleId">{{ role }}</option>
                </select>
            </div>
            <div v-bind:class="{ toggle_hide: toggleHide }" class="col-md-3 col-sm-4 col-xs-6 form-group  ">
                <label>Default CC</label>
                <Typeahead ref="defaultCC" cdi_type='search_my_users' @objectSelected="setDefaultCc"  />
            </div>
            <div v-bind:class="{ toggle_hide: toggleHide }" class="col-md-3 col-sm-4 col-xs-6 form_grid  ">
                <label><input type=checkbox  :true-value="1" :false-value=null v-model="state.optionFields.enabledOnly.val" > Enabled Only</label>
            </div>
            <div v-bind:class="{ toggle_hide: toggleHide }" class="col-md-3 col-sm-4 col-xs-6 form_grid  ">
                <label><input type=checkbox :true-value="1" :false-value=null v-model="state.optionFields.hasGapps.val" > Has GApps</label>
            </div>
            <div v-bind:class="{ toggle_hide: toggleHide }" class="col-md-3 col-sm-4 col-xs-6 form_grid  ">
                <label><input type=checkbox :true-value="'0000-00-00'" :false-value=null v-model="state.optionFields.noTerms.val"> No Terms</label>
            </div>

            <div v-bind:class="{ toggle_hide: toggleHide }" class="col-md-3 col-sm-4 col-xs-6 form-group   date_picker">
                <label>Last Login From</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.lastLoginFrom.val" />
            </div>
            <div v-bind:class="{ toggle_hide: toggleHide }" class="col-md-3 col-sm-4 col-xs-6 form-group   date_picker">
                <label>Last Login To</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.lastLoginTo.val" />
            </div>
            <div v-bind:class="{ toggle_hide: toggleHide }" class="col-md-3 col-sm-4 col-xs-6 form-group   date_picker">
                <label>Register Date From</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.registerDateFrom.val" />
            </div>
            <div v-bind:class="{ toggle_hide: toggleHide }" class="col-md-3 col-sm-4 col-xs-6 form-group date_picker">
                <label>Register Date To</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.registerDateTo.val" />
            </div>
            <div v-bind:class="{ toggle_hide: toggleHide }" class="col-md-3 col-sm-4 col-xs-6 form-group   date_picker">
                <label>Terms Date From</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.termsDateFrom.val" />
            </div>
            <div v-bind:class="{ toggle_hide: toggleHide }" class="col-md-3 col-sm-4 col-xs-6 form-group   date_picker">
                <label>Terms Date To</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.termsDateTo.val" />
            </div>
            <div v-bind:class="{ toggle_hide: toggleHide }" class="col-md-3 col-sm-4 col-xs-6 form-group   date_picker">
                <label>Quote From</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.quoteFrom.val" />
            </div>
            <div v-bind:class="{ toggle_hide: toggleHide }" class="col-md-3 col-sm-4 col-xs-6 form-group   date_picker">
                <label>Quote To</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.quoteTo.val" />
            </div>
            <div v-bind:class="{ toggle_hide: toggleHide }" class="col-md-3 col-sm-4 col-xs-6 form-group  date_picker">
                <label>Order From</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.orderFrom.val" />
            </div>
            <div v-bind:class="{ toggle_hide: toggleHide }" class="col-md-3 col-sm-4 col-xs-6 form-group  date_picker">
                <label>Order To</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.orderTo.val" />
            </div>

            <div class="col-xs-12">
                <button type="button" class="btn btn-default btn-sm" @click="toggleMoreFilters">
                    {{ (toggleHide) ? 'More' : 'Less' }} Filters
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import userCache from '@/cache/user.cache.js';
    import stateCache from '@/cache/state.cache.js';
    import brandCache from '@/cache/brand.cache.js';
    import cdiRoleCache from '@/cache/cdiRole.cache.js';
    import Typeahead from '@/components/utils/typeaheads/Typeahead';
    import DatePicker from "@/components/utils/DatePicker";

    export default {
        name: "Options",
        components: { DatePicker, Typeahead },

        data() {
            return {
                state: store.state,
                toggleHide: true,
                cache: {
                    cdiLocationCache,
                    userCache,
                    stateCache,
                    brandCache,
                    cdiRoleCache
                },
                nationalSalesTeams: [
                  [0,'Options Not Available']],
            }
        },
      created() {
        this.get_national_sales_teams();
      },
        methods: {
            toggleMoreFilters: function () {
                this.toggleHide = !this.toggleHide;
                if(this.toggleHide){
                    this.$refs.defaultCC.object = '';
                    this.$refs.regionalSalesRep.object ='';
                    this.$refs.salesSupportRep.object = '';
                }
            },
            setDefaultCc: function(userData) {
                this.state.optionFields.defaultCcId.val = userData.id;
            },
            get_national_sales_teams: function() {
              store.customAPI('menu_builder/national_sales_teams').then(res => this.nationalSalesTeams = res);
            }
        },
        watch:{
            toggleHide: function () {
                this.state.toggleHide = this.toggleHide;
            }
        }
    }
</script>

<style scoped>

</style>