<template>
    <div>
        <h1>People</h1>
        <form @submit.prevent="runReport">
            <Options />
            <GroupBy />
            <Fields />
            <Results />
        </form>
    </div>
</template>

<script>
import { store } from '@/store/Report.store.js'
import Options from './user_rpt_opt.vue'
import GroupBy from './utils/GroupBy.vue'
import Fields from './utils/Fields.vue'
import Results from "./utils/Results";

export default {
    name: "user_rpt",
    components: {
        Options,
        GroupBy,
        Fields,
        Results,
    },
    data() {
        return {
            state: store.state,
            optionFields: {
                email: {val: '', qsField: '[strings][u.email]'},
                firstName: {val: '', qsField: '[strings][u.first_name]'},
                lastName: {val:'', qsField: '[strings][u.last_name]'},
                userType: {val: (this.$route.query.user_type_search) ? this.$route.query.user_type_search : '0', qsField: '[numbers][u.user_type_id]'},
                companyName: {val: (this.$route.query.company_search) ? this.$route.query.company_search : ''},
                companyId: {val: (this.$route.query.company_search_id) ? this.$route.query.company_search_id : '0', qsField: '[numbers][u.company_id]'},
                branchId: {val: 0, qsField: '[numbers][br.branch_id]'},
                city: {val: '', qsField: '[strings][city]'},
                state: {val: '0', qsField: '[strings][ua.state]'},
                zip: {val: '', qsField: '[strings][zip]'},
                phone: {val: '', qsField: '[strings][phone]', defaultVal: ''},
                outsideSalesId: {val: 0, qsField: '[numbers][sales_user_id]'},
                insideSalesId: {val: 0, qsField: '[numbers][inside_sales_user_id]'},
                nationalSalesTeams: {val: 0, qsField: '[numbers][u.national_sales_team_id]'},
                brand: {val: '0', qsField: '[numbers][u.brand_id]', defaultVal: '0'},
                cdiRole: {val: '0', qsField: '[numbers][usr.cdi_role_id]', defaultVal: '0'},
                defaultCcId: {val: '0', qsField: '[default_cc_id]', defaultVal: '0'},
                enabledOnly: {val: '1', qsField: '[isnot][u.disable]', defaultVal: '1'},
                hasGapps: {val: '', qsField: '[isset][has_gapps]', defaultVal: ''},
                noTerms: {val: '', qsField: '[strings][u.terms_date]', defaultVal: ''},
                lastLoginFrom: {val: '', qsField: '[dates][uw.last_login_from]', defaultVal: ''},
                lastLoginTo: {val: '', qsField: '[dates][uw.last_login_to]', defaultVal: ''},
                registerDateFrom: {val: '', qsField: '[dates][uw.register_date_from]', defaultVal: ''},
                registerDateTo: {val: '', qsField: '[dates][uw.register_date_to]', defaultVal: ''},
                termsDateFrom: {val: '', qsField: '[dates][uw.terms_date_from]', defaultVal: ''},
                termsDateTo: {val: '', qsField: '[dates][uw.terms_date_to]', defaultVal: ''},
                quoteFrom: {val: '', qsField: '[has_quote_date_from]', defaultVal: ''},
                quoteTo: {val: '', qsField: '[has_quote_date_to]', defaultVal: ''},
                orderFrom: {val: '', qsField: '[has_order_date_from]', defaultVal: ''},
                orderTo: {val: '', qsField: '[has_order_date_to]', defaultVal: ''},
                cdiLocationId: {val: 0, qsField: '[numbers][assigned_cdi_location_id]'},
            },
            groupByFields: {
                company_name: {label: 'Company'},
                city: {label: 'City'},
                state: {label: 'State'},
                user_type: {label: 'User Type'},
                brand: {label: 'Brand ID'},
                price_level: {label: 'Level'},
                outside_sales: {label: 'Regional Sales Rep'},
                inside_sales: {label: 'Sales Support Rep'}
            },
            dataFields: {
                user_id: {label: 'ID', checked: true},
                email: {label: 'Email', checked: true},
                first_name: {label: 'First Name', checked: true},
                last_name: {label: 'Last Name', checked: true},
                company_name: {label: 'Company Name', checked: true},
                branch: {label: 'Branch', checked: false},
                user_type: {label: 'User Type', checked: true},
                user_roles: {label: 'User Roles'},
                address: {label: 'Address'},
                city: {label: 'City'},
                state: {label: 'State'},
                zip: {label: 'Zip'},
                phone: {label: 'Phone'},
                mphone: {label: 'Mobile'},
                brand: {label: 'Brand'},
                price_level: {label: 'Level'},
                outside_sales: {label: 'Regional Sales Rep'},
                inside_sales: {label: 'Sales Support Rep'},
                national_sales_team: {label: 'National Sales Team'},
                emergency_contact: {label: 'Emergency Contact'},
                last_login: {label: 'Last Login'},
                last_quote_date: {label: 'Last Quote Date'},
                last_order_date: {label: 'Last Order Date'},
                title: {label:'Title'}
            },
        }
    },
    props: ['action'],
    methods: {
        runReport: function() {
            store.runReport();
        },
    },
    created() {
        this.$appStore.setTitle(['People']);
        this.$set(store.state.results, this.$options.name, '');
        store.state.runOnLoad = false;
        store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
    },

}
</script>

<style scoped>

</style>