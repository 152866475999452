export default {
    ROLES: [
        [1, 'Super'],
        [2, 'Human Resources'],
        [3, 'Sales'],
        [4, 'Accounting'],
        [5, 'Engineering'],
        [6, 'Quality Control'],
        [7, 'Shop'],
        [8, 'Shop Supervisor'],
        [9, 'Logistics'],
        [10, 'Manager'],
        [11, 'Material'],
        [12, 'Leadership'],
        [13, 'Sales Support'],
        [14, 'Batching']
    ]
}